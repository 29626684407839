<template>
  <v-container fluid>
    <v-card style="width: 100%">
      <v-card-text>
        <v-item-group
            flex
            class="flex row ml-6 mt-1"
            style="align-content: space-between"
        >
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 260px"
              :items="classes"
              item-text="full_name"
              item-value="IDFaecher"
              :return-object="true"
              label="Klasse"
              v-model="selectedClass"
              class="mr-2"
              no-data-text="Loading..."
              clearable
          />
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 260px"
              :items="subjects"
              :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
              item-value="IDFaecher"
              label="Fächer"
              :return-object="true"
              v-model="selectedSubject"
              class="mr-2"
              no-data-text="Loading..."
              :disabled="!subjects.length"
              clearable
          />
        </v-item-group>
      </v-card-text>
    </v-card>
    <br/>
    <v-data-table
        :headers="headers"
        :items="contents"
        disable-pagination
        :hide-default-footer="true"
        class="elevation-1"
        :loading="loading"
        v-if="contents"
    >
      <template v-slot:[`item.IDSchueler`]="{ item }">
        <v-avatar class="ml-3">
          <img :src="item.avatar"/>
        </v-avatar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ getFullName(item) }}</strong><br>
        <small style="color:green" class="text-green" v-show="item.saved">gespeichert</small>
        <small style="color:red" class="text-red" v-show="item.error">Fehler beim Speichern</small>
      </template>

      <template v-slot:[`item.comments`]="{ item }">
        <v-textarea rows="4" label="Hinweise..." v-model="item.comment" @change="save(item)">
        </v-textarea>
      </template>

      <template v-slot:[`item.betreuung`]="{ item }">
        <v-text-field
            v-model="item.dateFrom"
            label="Von"
            type="date"
            :min="minDate"
            prepend-icon="mdi-calendar"
            @change="save(item)"
        ></v-text-field>

        <v-text-field
            v-model="item.dateTo"
            label="Bis"
            type="date"
            :min="minDate"
            prepend-icon="mdi-calendar"
            @change="save(item)"
        ></v-text-field>
      </template>

    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {useHttp} from '@/core/http'

const HomeworkProps = Vue.extend({
  props: {
    selectedClassId: String,
    selectedSubjectId: String
  }
})

@Component
export default class HomeworkCreate extends HomeworkProps {
  private loading = false
  private classes: Array<any> = []
  private selectedClass: Record<string, any> = {}
  private subjects: Array<any> = []
  private selectedSubject: Record<string, any> = {}
  private contents = null
  private http = useHttp()
  private headers: Array<Record<string, any>> = [
    {text: '', value: 'IDSchueler', width: 100},
    {text: 'Schüler', value: 'name'},
    {text: 'Hausaufgabenbetreuung', value: 'betreuung'},
    {text: 'Hinweise', value: 'comments', sortable: false},
  ];
  private fromDateMenu = false
  private fromDateVal = null

  private minDate = this.$moment().format('YYYY-MM-DD')

  public updateDateForItem(value: any) {
    console.log(value)
  }

  public async save(item: Record<string, any>) {

    let isValidated: boolean
    let alertMessageValidation: string = ''

    if (item.dateFrom < this.minDate && !item.homework) {
      // Catch new homework assignment for which the start date is set in the past.
      alertMessageValidation = 'Das Startdatum darf nicht in der Vergangenheit liegen.'
      item.dateFrom = this.$moment().format('YYYY-MM-DD')
      isValidated = false
    } else if (item.dateTo < this.minDate && !item.homework) {
      // Catch new homework assignment for which the end date is set in the past.
      alertMessageValidation = 'Das Enddatum darf nicht in der Vergangenheit liegen.'
      item.dateTo = null
      isValidated = false
    } else if (item.dateFrom > item.dateTo) {
      // Catch homework assignment for which the end date is earlier than the start date.
      alertMessageValidation = 'Das Enddatum kann nicht vor dem Startdatum liegen.'
      isValidated = false
    } else if (!item.dateFrom && !item.dateTo && !item.homework) {
      // Catch new homework assignment for which no date is given.
      alertMessageValidation = 'Das Start- und Enddatum muss angegeben werden.'
      isValidated = false
    } else if (item.dateFrom && !item.dateTo || !item.dateFrom && item.dateTo) {
      // Catch homework assignment for which only start or end date is given. Show no alert.
      alertMessageValidation = ''
      isValidated = false
    } else {
      isValidated = true
    }

    if (isValidated) {
      let alertMessageCreation: string

      const payload = {
        "subject": this.selectedSubject['IDFaecher'],
        "start": item.dateFrom,
        "end": item.dateTo || "",
        "comment": item.comment || ""
      }
      if (item.homework) {
        // Homework assigment already exists
        alertMessageCreation = 'Die Hausaufgabenbetreuung wurde erfolgreich aktualisiert!'
      } else {
        // No homework assignment exists.
        alertMessageCreation = 'Die Hausaufgabenbetreuung wurde erfolgreich erstellt!'
      }
      try {
        const res = await this.http.post('homeworks/book/' + item.IDSchueler, payload)
        if (res.status == true) {
          this.$swal.fire({
            title: 'Erfolgreich!',
            text: alertMessageCreation,
            allowOutsideClick: false,
            confirmButtonText: 'Okay',
            icon: 'success'
          })
          item.saved = true
          // Save homework assigment to item. This is necessary to prevent that the assigment is tagged as new.
          Object.assign(
              item,
              {dateFrom: res.data.abWann, dateTo: res.data.bisWann, comment: res.data.hinweise}
          )
          item.homework = res.data
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: 'Es ist ein Fehler aufgetreten!',
          allowOutsideClick: false,
          confirmButtonText: 'Okay',
          icon: 'error'
        })
      }
    } else {
      if (alertMessageValidation) {
        this.$swal.fire({
          title: 'Warnung!',
          text: alertMessageValidation,
          allowOutsideClick: false,
          confirmButtonText: 'Okay',
          icon: 'warning'
        })
      }
    }
  }

  public getFullName(schueler: any): string {
    return `${schueler.vorname} ${schueler.nachname}`
  }

  public getHintForItem(item: Record<string, any>) {
    if (item.homework) {
      Object.assign(item.homework, {hinweise: "testing"})
    }
    return item.homework['hinweise']
  }

  public getGruppe(schueler: any): string {
    return `${schueler.SchulArtGruppe}`
  }

  public updateExistence(item: any): void {
    //this.save(item)
  }

  public fromDateDisp() {
    return this.fromDateVal;
  }

  @Watch('selectedClass')
  private async classUpdater() {
    // get objects for class
    this.selectedSubject = {}
    this.loading = true
    try {
      this.subjects = await this.http.get(`homeworks/subjects`)

      if (this.selectedSubjectId) {
        this.selectedSubject = this.subjects.find(item => item.IDFaecher == this.selectedSubjectId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  @Watch('selectedSubject')
  private async subjectUpdate() {
    // get subjects for class
    if (Object.entries(this.selectedSubject).length) {
      this.loading = true
      try {
        const res = await this.http.get(`homeworks/book/list/${this.selectedClass.IDArtSchule}/${this.selectedSubject.IDFaecher}`)
        // transform data to speciefied object structure
        // { comment, dateFrom, dateTo}
        this.contents = res.map((item: Record<string, any>) => {
          if (item.homework) Object.assign(item, {
            dateFrom: item.homework.abWann,
            dateTo: item.homework.bisWann,
            comment: item.homework.hinweise
          })
          return item
        }) || {}

      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false;
      }
    }
  }

  private async mounted() {
    this.loading = true
    // get data for classes and subjects
    try {
      const {data}: { data: any } = await this.http.get('classes')
      this.classes = data;

      if (this.selectedClassId) {
        this.selectedClass = this.classes.find(item => item.IDArtSchule == this.selectedClassId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }
}
</script>
