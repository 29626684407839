
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between"
      >
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="classes"
            item-text="full_name"
            item-value="IDFaecher"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="mr-2"
            no-data-text="Loading..."
            clearable
        />
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="subjects"
            :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
            item-value="IDFaecher"
            label="Fächer"
            :return-object="true"
            v-model="selectedSubject"
            class="mr-2"
            no-data-text="Loading..."
            :disabled="!subjects.length"
            clearable
        />
      </v-item-group>
    </v-card-text>
  </v-card>
  <br/>
  <v-data-table
      :headers="headers"
      :items="contents"
      disable-pagination
      :hide-default-footer="true"
      class="elevation-1"
      :loading="loading"
      v-if="contents"
  >
    <template v-slot:[`item.IDSchueler`]="{ item }">
      <v-avatar class="ml-3">
        <img :src="item.avatar"/>
      </v-avatar>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <strong>{{ getFullName(item) }}</strong><br>
      <small style="color:green" class="text-green" v-show="item.saved">gespeichert</small>
      <small style="color:red" class="text-red" v-show="item.error">Fehler beim Speichern</small>
    </template>

    <template v-slot:[`item.comments`]="{ item }">
      <v-textarea rows="4" label="Hinweise..." v-model="item.comment" @change="save(item)">
      </v-textarea>
    </template>

    <template v-slot:[`item.betreuung`]="{ item }">
      <v-text-field
          v-model="item.dateFrom"
          label="Von"
          type="date"
          :min="minDate"
          prepend-icon="mdi-calendar"
          @change="save(item)"
      ></v-text-field>

      <v-text-field
          v-model="item.dateTo"
          label="Bis"
          type="date"
          :min="minDate"
          prepend-icon="mdi-calendar"
          @change="save(item)"
      ></v-text-field>
    </template>

  </v-data-table>
</v-container>
